
const bloglinks = [
    {
        id: 1,
        toblog: '/blog',
        nameblog: 'Blog'
    },
    {
        id: 2,
        toblog: '/blog-single',
        nameblog: 'Blog Single'
    }
]

export default bloglinks ;