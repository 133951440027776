import Home02 from './Home02';
import Home03 from './Home03';
import Home04 from './Home04';
// import Blog from './Blog';

const routes = [
    { path: "/", component: Home04},
    { path: "/home-02", component: Home02},
    { path: "/home-03", component: Home03},
    { path: "/home-04", component: Home04},
    // { path: "/blog", component: Blog},
    // { path: "/blog-single", component: BlogSingle},
]

export default routes;