const menus = [
    {
        id: 1,
        tomenu: "#about",
        namemenu: "About"
    },
    {
        id: 2,
        tomenu: "#services",
        namemenu: "services"
    },
    {
        id: 3,
        tomenu: "#portfolio",
        namemenu: "Portfolio"
    },
    {
        id: 4,
        tomenu: "#testimonial",
        namemenu: "Testimonial"
    },
    {
        id: 5,
        tomenu: "#resume",
        namemenu: "Resume"
    },
];



export default menus ;